<template>
  <div class="saleBack">
    <h1>销售明细（到达）后台</h1>
    <el-form :inline="true">
      <el-form-item label="发货日期">
        <el-date-picker v-model="searchParam.sendDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="到货日期">
        <el-date-picker v-model="searchParam.receiveDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="到货状态">
        <el-select v-model="searchParam.hasReceive" filterable style="width: 120px;" clearable>
          <el-option label="运输中" :value="false" />
          <el-option label="已到货" :value="true" />
        </el-select>
      </el-form-item>
      <el-form-item label="运费校对">
        <el-select v-model="searchParam.hasCheckTransportAmount" filterable style="width: 120px;" clearable>
          <el-option label="未通过" :value="false" />
          <el-option label="已通过" :value="true" />
        </el-select>
      </el-form-item>
      <el-form-item label="数量校对">
        <el-select v-model="searchParam.saleSampleIdIsNull" filterable style="width: 120px;" clearable>
          <el-option label="未通过" :value="true" />
          <el-option label="已通过" :value="false" />
        </el-select>
      </el-form-item>
      <el-form-item label="客户">
        <el-select v-model="searchParam.custom" filterable style="width: 120px;" clearable>
          <el-option v-for='customItem in show.customList' :key="customItem.id" :label="customItem.value" :value="customItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="仓储">
        <el-select v-model="searchParam.storage" filterable style="width: 120px;" clearable>
          <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="车号">
        <el-autocomplete v-model="searchParam.truckNumber" style="width: 120px;" :fetch-suggestions="getTruckNumbers" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button type="info" @click="exportExcel()">导出</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="info" @click="show.importShow = true">导入</el-button>
          <el-button type="primary" @click="add()">新建</el-button>
          <el-button type="warning" :disabled="!(show.selected.length == 1)" @click="update()">详情</el-button>
          <el-button type="danger" :disabled="!(show.selected.length >= 1)" @click="del()">删除</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" show-summary :summary-method="summary" border style="height: calc(100vh - 310px);">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column width="55" align="center">
        <template #default="scope">
          <el-link type="primary" @click="update(scope.row)">详情</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="sendDate" label="发货日期" width="120" show-overflow-tooltip />
      <el-table-column prop="receiveDate" label="到货日期" width="120" show-overflow-tooltip />
      <el-table-column prop="truckNumber" label="车号" width="120" show-overflow-tooltip />
      <el-table-column prop="transportCompany" label="运输公司" width="120" show-overflow-tooltip />
      <el-table-column prop="storage" label="仓储" width="120" show-overflow-tooltip />
      <el-table-column prop="custom" label="客户" width="120" show-overflow-tooltip />
      <el-table-column prop="sendQuantityInitial" label="发货数量" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.sendQuantityInitial) }}
        </template>
      </el-table-column>
      <el-table-column prop="sendQuantity" label="实际出库" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.sendQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="receiveQuantity" label="最终到货" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.receiveQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="receiveQuantityBack" label="退车数量" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.receiveQuantityBack) }}
        </template>
      </el-table-column>
      <el-table-column prop="lossQuantity" label="运输亏吨" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.lossQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="customNote" label="销售品名" width="120" show-overflow-tooltip />
      <el-table-column prop="saleMixDetailList" label="配比" width="300" show-overflow-tooltip>
        <template #default="scope">
          <span v-for="(item, index) in scope.row.saleMixDetailList" :key="item.product">
            <el-text style="margin: 0 10px;" v-if="index != 0">:</el-text>
            <el-text>{{ item.product + '[' + item.mixNumber + ']' + '(' + item.sendQuantity + ')' }}</el-text>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="transportPrice" label="运输单价" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.transportPrice) }}
        </template>
      </el-table-column>
      <el-table-column prop="transportDeductionAmount" label="运费扣除" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.transportDeductionAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="transportAmount" label="实付运费" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.transportAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="note" label="备注信息" width="120" show-overflow-tooltip />
      <el-table-column prop="hasCheckTransportAmount" label="运费校对" width="90" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.hasCheckTransportAmount">已通过</el-tag>
          <el-tag type="info" v-else>未通过</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="saleSampleId" label="数量校对" width="90" show-overflow-tooltip>
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.saleSampleId">已通过</el-tag>
          <el-tag type="info" v-else>未通过</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
    <el-pagination v-model:current-page="searchParam.pageNum" v-model:page-size="searchParam.pageSize" :page-sizes="[50, 100, 200]" background layout="total, sizes, prev, pager, next, jumper" :total="data.total" @change="commitFindList()" />
  </div>

  <el-dialog v-model="show.importShow" title="确认导入" width="500">
    <el-form>
      <el-form-item>
        <el-text>请先</el-text>
        <el-link type="primary" @click="importExcelTemplate()">下载导入模版</el-link>
        <el-text>，填写模板内容再导入。</el-text>
        <br>
        <el-text type="danger">模板格式不对应可能造成倒入数据混乱，请注意。</el-text>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-upload :action="api.baseURL() + '/backend/sale/importExcel'" :headers="api.tokenHeader()" :on-progress="importExcelProgress" :on-success="importExcelSuccess" :on-error="importExcelError" :show-file-list="false">
          <el-button type="primary">导入</el-button>
        </el-upload>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import apiNative from '../../apis/apiNative'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  total: 0,
  list: [],
  accumulate: {},
})

const show = reactive({
  selected: [],
  customList: [],
  storageList: [],
  importShow: false,
})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})

const now = new Date()
const begin = new Date(now.getTime() - 3 * 24 * 60 * 60 * 1000)
const end = new Date(now.getTime() - 1 * 24 * 60 * 60 * 1000)

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.pageNum = 1
  searchParam.pageSize = 50
  searchParam.sendDates = [begin, end]
  searchParam.receiveDates = []
  searchParam.hasCheckTransportAmount = null
  searchParam.hasReceive = null
  searchParam.saleSampleIdIsNull = null
  searchParam.custom = null
  searchParam.storage = null
  searchParam.truckNumber = null
}
resetSearchParam()
const beforeSearchParam = cache.getObject(cache.keys.pageParam + 'sale')
if (beforeSearchParam) {
  Object.assign(searchParam, beforeSearchParam)
  cache.setObject(cache.keys.pageParam + 'sale', null)
}

const getTruckNumbers = (queryString, callback) => {
  api.get('/backend/sale/getTruckNumbers', { params: { truckNumberLike: queryString } }).then(res => {
    callback(res.list.map(item => { return { value: item } }))
  })
}

const getPageParam = () => {
  const params = {}
  params.orderBy = 'sendDate_desc_id_desc'
  params.pageNum = searchParam.pageNum
  params.pageSize = searchParam.pageSize
  if (searchParam.sendDates && searchParam.sendDates.length == 2) {
    params.sendDateGe = util.parseTime(searchParam.sendDates[0], '{y}-{m}-{d}')
    params.sendDateLe = util.parseTime(searchParam.sendDates[1], '{y}-{m}-{d}')
  }
  if (searchParam.receiveDates && searchParam.receiveDates.length == 2) {
    params.receiveDateGe = util.parseTime(searchParam.receiveDates[0], '{y}-{m}-{d}')
    params.receiveDateLe = util.parseTime(searchParam.receiveDates[1], '{y}-{m}-{d}')
  }
  params.hasCheckTransportAmount = searchParam.hasCheckTransportAmount
  params.hasReceive = searchParam.hasReceive
  params.saleSampleIdIsNull = searchParam.saleSampleIdIsNull
  params.custom = searchParam.custom
  params.storage = searchParam.storage
  params.truckNumber = searchParam.truckNumber
  return params
}

const commitFindList = () => {
  api.get('/backend/sale/page', { params: getPageParam() }).then(res => {
    data.list = res.list
    data.total = res.total
    data.accumulate = res.data
  })
}
commitFindList()

const summary = () => {
  return ['合计', '', '', '', math.formatNumber(data.accumulate.count), '', '', '', math.formatNumber(data.accumulate.sendQuantity), '', math.formatNumber(data.accumulate.receiveQuantity), '', '', '', '', '', '', math.formatNumber(data.accumulate.transportAmount)]
}

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const add = () => {
  cache.setObject(cache.keys.pageParam + 'sale', searchParam)
  cache.setObject(cache.keys.pageParam + 'saleDetail', null)
  router.push('/saleDetail')
}

const update = (row) => {
  cache.setObject(cache.keys.pageParam + 'sale', searchParam)
  const sale = row ? row : tabRef.value.getSelectionRows()[0]
  cache.setObject(cache.keys.pageParam + 'saleDetail', sale)
  router.push('/saleDetail')
}

const del = () => {
  const ids = tabRef.value.getSelectionRows().map(sale => sale.id)
  ElMessageBox.confirm('', {
    title: '确认删除',
    message: '将要删除选中的 ' + ids.length + ' 项。',
    autofocus: false,
    confirmButtonText: '确认',
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/sale/del', { ids: ids }).then(() => {
      ElMessage.success('删除成功')
      commitFindList()
    })
  })
}

const importExcelTemplate = () => {
  apiNative.get('/backend/sale/importExcelTemplate', {
    responseType: 'blob'
  }).then(res => {
    ElMessage.success('开始下载')
    util.downloadFile(res, '销售明细-导入模版', 'xlsx')
  }).catch(() => {
    ElMessage.error('下载失败，请联系系统管理员')
  })
}

const importExcelProgress = () => {
  ElMessage.success('正在导入！如果数据量大可能时间较长，请耐心等待。')
}

const importExcelSuccess = (res) => {
  if (res.code != 'S000001') {
    ElMessage.error('导入失败，请联系系统管理员')
    return
  }
  show.importShow = false
  let message = '<p>本次导入，共计识别到：' + res.data.all + '条数据</p>'
  message += '<p>成功：<span style="color:green"> ' + res.data.success + ' </span>条数据</p>'
  message += '<p>失败：<span style="color:red"> ' + res.data.error + ' </span>条数据</p>'
  if (res.data.error > 0) {
    for (const failIndex in res.data.failRowList) {
      message += '<p>第 ' + res.data.failRowList[failIndex].row + ' 行：' + res.data.failRowList[failIndex].reason + '</p>'
    }
  }
  ElMessageBox.alert('', {
    title: '导入完成',
    message: message,
    autofocus: false,
    dangerouslyUseHTMLString: true,
    confirmButtonText: '确定'
  })
  res.data
}

const importExcelError = () => {
  ElMessage.error('导入失败，请联系系统管理员')
}

const exportExcel = () => {
  ElMessageBox.confirm('', {
    title: '确认导出',
    message: '将要按照当前选择的查询条件进行导出。',
    autofocus: false,
    confirmButtonText: '确认',
    cancelButtonText: '取消'
  }).then(() => {
    ElMessage.success('正在导出！如果数据量大可能时间较长，请耐心等待。')
    apiNative.get('/backend/sale/exportExcel', {
      params: getPageParam(),
      responseType: 'blob'
    }).then(res => {
      searchParam.exportShow = false
      ElMessage.success('开始下载')
      util.downloadFile(res, '销售明细' + util.parseTime(new Date(), '{y}{m}{d}{h}{i}{s}'), 'xlsx')
    }).catch(() => {
      ElMessage.error('导出失败，请联系系统管理员')
    })
  })
}
</script>

<style lang="less"></style>